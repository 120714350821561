<template>
    <section class="calendar-section">
        <div class="container">
            <h2 alt="Class Schedule">
                SCHEDULE 
            </h2>
            <div class="calendar-container">
                <div class="days">
                    <div></div>
                    <div>Monday</div>
                    <div>Tuesday</div>
                    <div>Wednesday</div>
                    <div>Thursday</div>
                    <div>Friday</div>
                    <div>Saturday</div>
                    <div>Sunday</div>
                </div>
                <div class="calendar-rows">
                    <div class="time"> 
                        <p> 6:00 </p>
                    </div>
                     <div class="schedule"> 
                        <p class="type">Health</p>
                        <p class="slots">3 Slots left</p>
                        <p class="time"> 6:00 - 7:00</p>
                        <p class="coach"> Coach Roach</p>
                    </div>
                    <div class="schedule">
                        
                    </div>
                    <div class="schedule">
                        <p class="type">Health</p>
                        <p class="slots">3 Slots left</p>
                        <p class="time"> 6:00 - 7:00</p>
                        <p class="coach"> Coach Roach</p>
                    </div>
                    <div class="schedule"></div>
                    <div class="schedule">
                        <p class="type">Health</p>
                        <p class="slots">3 Slots left</p>
                        <p class="time"> 6:00 - 7:00</p>
                        <p class="coach"> Coach Roach</p>
                    </div>
                    <div class="schedule"></div>
                    <div class="schedule">
                        <p class="type">Health</p>
                        <p class="slots">3 Slots left</p>
                        <p class="time"> 6:00 - 7:00</p>
                        <p class="coach"> Coach Roach</p>
                    </div>
                </div>  
                <div class="calendar-rows">
                    <div class="time"> 
                        <p> 6:00 </p>
                    </div>
                     <div class="schedule"> 
                        <p class="type">Health</p>
                        <p class="slots">3 Slots left</p>
                        <p class="time"> 6:00 - 7:00</p>
                        <p class="coach"> Coach Roach</p>
                    </div>
                    <div class="schedule"></div>
                    <div class="schedule">
                        <p class="type">Health</p>
                        <p class="slots">3 Slots left</p>
                        <p class="time"> 6:00 - 7:00</p>
                        <p class="coach"> Coach Roach</p>
                    </div>
                    <div class="schedule"></div>
                    <div class="schedule">
                        <p class="type">Health</p>
                        <p class="slots">3 Slots left</p>
                        <p class="time"> 6:00 - 7:00</p>
                        <p class="coach"> Coach Roach</p>
                    </div>
                    <div class="schedule"></div>
                    <div class="schedule">
                        <p class="type">Health</p>
                        <p class="slots">3 Slots left</p>
                        <p class="time"> 6:00 - 7:00</p>
                        <p class="coach"> Coach Roach</p>
                    </div>
                </div>  
                <div class="calendar-rows">
                    <div class="time"> 
                        <p> 6:00 </p>
                    </div>
                     <div class="schedule"> 
                        <p class="type">Health</p>
                        <p class="slots">3 Slots left</p>
                        <p class="time"> 6:00 - 7:00</p>
                        <p class="coach"> Coach Roach</p>
                    </div>
                    <div class="schedule"></div>
                    <div class="schedule">
                        <p class="type">Health</p>
                        <p class="slots">3 Slots left</p>
                        <p class="time"> 6:00 - 7:00</p>
                        <p class="coach"> Coach Roach</p>
                    </div>
                    <div class="schedule"></div>
                    <div class="schedule">
                        <p class="type">Health</p>
                        <p class="slots">3 Slots left</p>
                        <p class="time"> 6:00 - 7:00</p>
                        <p class="coach"> Coach Roach</p>
                    </div>
                    <div class="schedule"></div>
                    <div class="schedule">
                        <p class="type">Health</p>
                        <p class="slots">3 Slots left</p>
                        <p class="time"> 6:00 - 7:00</p>
                        <p class="coach"> Coach Roach</p>
                    </div>
                </div>  
                <div class="calendar-rows">
                    <div class="time"> 
                        <p> 6:00 </p>
                    </div>
                     <div class="schedule"> 
                        <p class="type">Health</p>
                        <p class="slots">3 Slots left</p>
                        <p class="time"> 6:00 - 7:00</p>
                        <p class="coach"> Coach Roach</p>
                    </div>
                    <div class="schedule"></div>
                    <div class="schedule">
                        <p class="type">Health</p>
                        <p class="slots">3 Slots left</p>
                        <p class="time"> 6:00 - 7:00</p>
                        <p class="coach"> Coach Roach</p>
                    </div>
                    <div class="schedule"></div>
                    <div class="schedule">
                        <p class="type">Health</p>
                        <p class="slots">3 Slots left</p>
                        <p class="time"> 6:00 - 7:00</p>
                        <p class="coach"> Coach Roach</p>
                    </div>
                    <div class="schedule"></div>
                    <div class="schedule">
                        <p class="type">Health</p>
                        <p class="slots">3 Slots left</p>
                        <p class="time"> 6:00 - 7:00</p>
                        <p class="coach"> Coach Roach</p>
                    </div>
                </div>
            </div>
            <div class="calendar-container-mobile">
                <h3>Monday</h3>
                <ul>
                    <li>
                        <div class="schedule">
                            <span class="type"> Health </span>
                            <span class="coach"> Coach Roach </span>
                        </div>
                        <div class="time-slot"> 
                            <span class="time"> 2:00 </span>
                            <span class="slot"> 2 slots </span>
                        </div>
                    </li>
                    <li>
                        <div class="schedule">
                            <span class="type"> Health </span>
                            <span class="coach"> Coach Roach </span>
                        </div>
                        <div class="time-slot"> 
                            <span class="time"> 2:00 </span>
                            <span class="slot"> 2 slots </span>
                        </div>
                    </li>
                    <li>
                        <div class="schedule">
                            <span class="type"> Health </span>
                            <span class="coach"> Coach Roach </span>
                        </div>
                        <div class="time-slot"> 
                            <span class="time"> 2:00 </span>
                            <span class="slot"> 2 slots </span>
                        </div>
                    </li>
                </ul>
                <h3>Tuesday</h3>
                <ul>
                    <li>
                        <div class="schedule">
                            <span class="type"> Health </span>
                            <span class="coach"> Coach Roach </span>
                        </div>
                        <div class="time-slot"> 
                            <span class="time"> 2:00 </span>
                            <span class="slot"> 2 slots </span>
                        </div>
                    </li>
                    <li>
                        <div class="schedule">
                            <span class="type"> Health </span>
                            <span class="coach"> Coach Roach </span>
                        </div>
                        <div class="time-slot"> 
                            <span class="time"> 2:00 </span>
                            <span class="slot"> 2 slots </span>
                        </div>
                    </li>
                    <li>
                        <div class="schedule">
                            <span class="type"> Health </span>
                            <span class="coach"> Coach Roach </span>
                        </div>
                        <div class="time-slot"> 
                            <span class="time"> 2:00 </span>
                            <span class="slot"> 2 slots </span>
                        </div>
                    </li>
                </ul>
                 <h3>Wednesday</h3>
                <ul>
                    <li>
                        <div class="schedule">
                            <span class="type"> Health </span>
                            <span class="coach"> Coach Roach </span>
                        </div>
                        <div class="time-slot"> 
                            <span class="time"> 2:00 </span>
                            <span class="slot"> 2 slots </span>
                        </div>
                    </li>
                    <li>
                        <div class="schedule">
                            <span class="type"> Health </span>
                            <span class="coach"> Coach Roach </span>
                        </div>
                        <div class="time-slot"> 
                            <span class="time"> 2:00 </span>
                            <span class="slot"> 2 slots </span>
                        </div>
                    </li>
                    <li>
                        <div class="schedule">
                            <span class="type"> Health </span>
                            <span class="coach"> Coach Roach </span>
                        </div>
                        <div class="time-slot"> 
                            <span class="time"> 2:00 </span>
                            <span class="slot"> 2 slots </span>
                        </div>
                    </li>
                </ul>
                 <h3>Thursday</h3>
                <ul>
                    <li>
                        <div class="schedule">
                            <span class="type"> Health </span>
                            <span class="coach"> Coach Roach </span>
                        </div>
                        <div class="time-slot"> 
                            <span class="time"> 2:00 </span>
                            <span class="slot"> 2 slots </span>
                        </div>
                    </li>
                    <li>
                        <div class="schedule">
                            <span class="type"> Health </span>
                            <span class="coach"> Coach Roach </span>
                        </div>
                        <div class="time-slot"> 
                            <span class="time"> 2:00 </span>
                            <span class="slot"> 2 slots </span>
                        </div>
                    </li>
                    <li>
                        <div class="schedule">
                            <span class="type"> Health </span>
                            <span class="coach"> Coach Roach </span>
                        </div>
                        <div class="time-slot"> 
                            <span class="time"> 2:00 </span>
                            <span class="slot"> 2 slots </span>
                        </div>
                    </li>
                </ul>
                 <h3>Friday</h3>
                <ul>
                    <li>
                        <div class="schedule">
                            <span class="type"> Health </span>
                            <span class="coach"> Coach Roach </span>
                        </div>
                        <div class="time-slot"> 
                            <span class="time"> 2:00 </span>
                            <span class="slot"> 2 slots </span>
                        </div>
                    </li>
                    <li>
                        <div class="schedule">
                            <span class="type"> Health </span>
                            <span class="coach"> Coach Roach </span>
                        </div>
                        <div class="time-slot"> 
                            <span class="time"> 2:00 </span>
                            <span class="slot"> 2 slots </span>
                        </div>
                    </li>
                    <li>
                        <div class="schedule">
                            <span class="type"> Health </span>
                            <span class="coach"> Coach Roach </span>
                        </div>
                        <div class="time-slot"> 
                            <span class="time"> 2:00 </span>
                            <span class="slot"> 2 slots </span>
                        </div>
                    </li>
                </ul>
                 <h3>Saturday</h3>
                <ul>
                    <li>
                        <div class="schedule">
                            <span class="type"> Health </span>
                            <span class="coach"> Coach Roach </span>
                        </div>
                        <div class="time-slot"> 
                            <span class="time"> 2:00 </span>
                            <span class="slot"> 2 slots </span>
                        </div>
                    </li>
                    <li>
                        <div class="schedule">
                            <span class="type"> Health </span>
                            <span class="coach"> Coach Roach </span>
                        </div>
                        <div class="time-slot"> 
                            <span class="time"> 2:00 </span>
                            <span class="slot"> 2 slots </span>
                        </div>
                    </li>
                    <li>
                        <div class="schedule">
                            <span class="type"> Health </span>
                            <span class="coach"> Coach Roach </span>
                        </div>
                        <div class="time-slot"> 
                            <span class="time"> 2:00 </span>
                            <span class="slot"> 2 slots </span>
                        </div>
                    </li>
                </ul>
                 <h3>Sunday </h3>
                <ul>
                    <li>
                        <div class="schedule">
                            <span class="type"> Health </span>
                            <span class="coach"> Coach Roach </span>
                        </div>
                        <div class="time-slot"> 
                            <span class="time"> 2:00 </span>
                            <span class="slot"> 2 slots </span>
                        </div>
                    </li>
                    <li>
                        <div class="schedule">
                            <span class="type"> Health </span>
                            <span class="coach"> Coach Roach </span>
                        </div>
                        <div class="time-slot"> 
                            <span class="time"> 2:00 </span>
                            <span class="slot"> 2 slots </span>
                        </div>
                    </li>
                    <li>
                        <div class="schedule">
                            <span class="type"> Health </span>
                            <span class="coach"> Coach Roach </span>
                        </div>
                        <div class="time-slot"> 
                            <span class="time"> 2:00 </span>
                            <span class="slot"> 2 slots </span>
                        </div>
                    </li>
                </ul>
            </div>

            <button class="btn book"> Book a Schedule with us</button>

        </div>
    </section>
</template>
<style lang="scss" scoped>
@import '@/assets/scss/_mixins.scss';

.calendar-container-mobile{
    display: none;
}
.calendar-section{
    background: #000;
    margin-bottom: 0;
}
.calendar-container{
    display: flex;
    color: #fff;
    justify-content: flex-end;
    flex-wrap: wrap;
    flex   : 1;
    margin-bottom: 30px;
}
.calendar-container .calendar-rows{
    display: flex;
    color: #fff;
    justify-content: flex-end;
    flex-wrap: wrap;
    flex-basis : 100%;
}
.calendar-container .days{
    display: flex;
    color: #fff;
    justify-content: flex-end;
    flex-wrap: wrap;
    flex-basis: 100%;
    font-size: 12px;

}
.calendar-container .days div{
    display: flex;
    color: #fff;
    justify-content: center;
    flex-wrap: wrap;
    padding: 20px;
    flex: 1;
    text-align: center;
    align-items: center;
}
.calendar-container .calendar-rows div{
    display: flex;
    color: #fff;
    justify-content: center;
    flex-wrap: wrap;
    flex: 1;
    padding: 20px;
    align-items: center;
    border : solid #272727 1px;
}

p{
    font-size: 10px;
}
.time p{
    font-size: 20px;
}
.schedule .type{
    font-weight: 700;
    text-transform: uppercase;
}
.schedule .coach{
    font-weight: 700;
    text-transform: uppercase;
    color: #a7a1a1;
}
@include mobile{
    .calendar-container {
        display: none;
    }

    .calendar-container-mobile{
        display: flex;
        margin:10px 0px;
        flex-wrap: wrap;
    }
     .calendar-container-mobile h3{
         flex-basis: 100%;
         color:#fff;
         text-align: center;
         font-weight: 800;
        margin:10px 0px;
    }
    .calendar-container-mobile ul{
        display: flex;
        color: #fff;
        width: 100%;
        margin-bottom: 20px;
            flex-wrap: wrap;
    }
    .calendar-container-mobile ul li{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width:100%;
        border-bottom: solid #fff 1px;
        flex-basis: 100%;
    }

    .schedule, .time-slot{
        display: flex;
        flex-direction: column;
        padding: 5px; 
        flex: 1;
    }
    .schedule {
         text-align: left;
    }
    .time-slot {
         text-align: right;
    }
    .schedule .coach, .time-slot .slot{
        font-size: 12px;
    }

    .btn.book{
        margin: 0 auto;
    }


}
</style>